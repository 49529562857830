import * as React from 'react';
import { useCallback } from 'react';
import { Event, GetEventsDocument } from '../../resolver.types';
import { Route, useNavigate, useLocation, Routes } from 'react-router-dom';
import { noop } from '../../utils/text';
import { DataList } from '../../components/common/data-list/data-list.component';
import CreateBrandedContainerFromTopicPage from '../create-branded-container-from-topic/create-branded-container-from-topic.page';
import useCalculateEventsData from '../../hooks/use-calculate-events-data';
import useEventColumns from '../../hooks/use-event-columns';
import { Col, message } from 'antd';
import { AutoComplete } from '../../components/filters/inputs/common/autocomplete/autocomplete.component';
import { Range } from '../../components/filters/inputs/common/range/range.component';
import Genres from '../../components/filters/inputs/common/genres/genres.component';
import TagsWrapper from '../../components/filters/inputs/common/tags/tags-wrapper.component';
import EventCityOrZip from '../../components/filters/inputs/event/event-city-or-zip/event-city-or-zip.component';
import AssignEventsToBcPage from '../assign-events-to-bc/assign-events-to-bc.page';
import OrganizerInput from '../../components/filters/inputs/event/organizer-input/organizer-input.component';
import { PlusOutlined } from '@ant-design/icons';
import { DateSelectWrapper } from '../../components/filters/inputs/common/date-select/date-select.wrapper';
import { createApplicationLinkProvider } from 'yggdrasil-shared/utils/application-link-client/client';
import { AppContext, AppStoreApi } from '../../context/app.context';
import rssIcon from '../../assets/images/rss-feed-icon.svg';

export const EventsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const {
    state: { selectedBrand },
  } = React.useContext(AppContext) as AppStoreApi;

  const { handleQueryChange } = useCalculateEventsData();

  const columns = useEventColumns();

  const handleAssign = useCallback(
    (events: Event[]) => {
      return navigate(`${location.pathname}/assign`, { state: { events } });
    },
    [location, navigate]
  );

  const generateRSSFeedURL = useCallback(
    (events: Event[]) => {
      const eventIds = events.map((event) => event.sourceEventID);
      const eventIdsString = eventIds.join(',');
      const encodedEventIdsString = encodeURIComponent(eventIdsString);

      const applicationLinkProvider = createApplicationLinkProvider({
        baseApplicationLink: process.env.REACT_APP_BASE_APPLICATION_LINK,
      });
      const publicApiRoute = applicationLinkProvider.getPublicApiRoute();
      const brandShortcut = selectedBrand?.shortcut || 'RX';

      const rssFeedURL = `${publicApiRoute}/rss_maileon/feeds/branded-containers?brand=${brandShortcut}&sourceEventIDs=${encodedEventIdsString}`;

      navigator.clipboard
        .writeText(rssFeedURL)
        .then(() => {
          message.success('The RSS-Feed URL is copied to your clipboard.');
        })
        .catch(() => {
          message.error('Failed to copy RSS feed URL to clipboard.');
        });
    },
    [selectedBrand]
  );

  const generateRSSFeedURLTopics = useCallback((events: Event[]) => {
    const eventIds = events.map((event) => event.sourceEventID);
    const eventIdsString = eventIds.join(',');
    const encodedEventIdsString = encodeURIComponent(eventIdsString);

    const applicationLinkProvider = createApplicationLinkProvider({
      baseApplicationLink: process.env.REACT_APP_BASE_APPLICATION_LINK,
    });
    const publicApiRoute = applicationLinkProvider.getPublicApiRoute();

    const rssFeedURL = `${publicApiRoute}/rss_maileon/feeds/topics?sourceEventIDs=${encodedEventIdsString}`;

    navigator.clipboard
      .writeText(rssFeedURL)
      .then(() => {
        message.success('The RSS-Feed URL is copied to your clipboard.');
      })
      .catch(() => {
        message.error('Failed to copy RSS feed URL to clipboard.');
      });
  }, []);

  const generateRSSFeedURLBrandPrefix = useCallback((events: Event[]) => {
    const eventIds = events.map((event) => event.sourceEventID);
    const eventIdsString = eventIds.join(',');
    const encodedEventIdsString = encodeURIComponent(eventIdsString);

    const applicationLinkProvider = createApplicationLinkProvider({
      baseApplicationLink: process.env.REACT_APP_BASE_APPLICATION_LINK,
    });
    const publicApiRoute = applicationLinkProvider.getPublicApiRoute();
    const bcsByEventsURL = `${publicApiRoute}/rss_maileon/feeds/brand-prefix?sourceEventIDs=${encodedEventIdsString}`;

    navigator.clipboard
      .writeText(bcsByEventsURL)
      .then(() => {
        message.success('The RSS-Feed URL is copied to your clipboard.');
      })
      .catch(() => {
        message.error('Failed to copy RSS feed URL to clipboard.');
      });
  }, []);

  const generateRSSFeedURLExtended = useCallback((events: Event[]) => {
    const eventIds = events.map((event) => event.sourceEventID);
    const eventIdsString = eventIds.join(',');
    const encodedEventIdsString = encodeURIComponent(eventIdsString);

    const applicationLinkProvider = createApplicationLinkProvider({
      baseApplicationLink: process.env.REACT_APP_BASE_APPLICATION_LINK,
    });
    const publicApiRoute = applicationLinkProvider.getPublicApiRoute();
    const bcsByEventsURL = `${publicApiRoute}/rss_maileon/feeds/all-containers?sourceEventIDs=${encodedEventIdsString}`;

    navigator.clipboard
      .writeText(bcsByEventsURL)
      .then(() => {
        message.success('The RSS-Feed URL is copied to your clipboard.');
      })
      .catch(() => {
        message.error('Failed to copy RSS feed URL to clipboard.');
      });
  }, []);

  return (
    <>
      <CreateBrandedContainerFromTopicPage />
      <Routes>
        <Route path={`assign`} element={<AssignEventsToBcPage />} />
      </Routes>
      <DataList<Event>
        filters={[
          <Col key={1} xxl={5} xl={6} md={16} xs={24}>
            <AutoComplete
              label="Event title"
              name="title"
              placeholder="Event title..."
            />
          </Col>,
          <Col key={2} xxl={4} xl={4} md={16} xs={24}>
            <Range
              disabled={true}
              label="Price"
              suffix="€"
              name="price"
              onlyIntegers
              placeholder="Price..."
              tooltipText="Filter is currently not available"
            />
          </Col>,
          <Col key={3} xxl={4} xl={4} md={16} xs={24}>
            <DateSelectWrapper
              name="eventPeriod"
              placeholder="Event date..."
              label="Event date"
              timeSelection={false}
            />
          </Col>,
          <Col key={4} xxl={4} xl={4} md={16} xs={24}>
            <OrganizerInput
              mode="tags"
              showReset
              label="Organizer name or ID"
              name="organizers"
              placeholder="Organizer name or ID..."
            />
          </Col>,
          <Col key={5} xxl={4} xl={4} md={16} xs={24}>
            <DateSelectWrapper
              name="presaleStart"
              placeholder="Presale start..."
              label="Presale start"
              timeSelection={false}
            />
          </Col>,
          <Col key={6} xxl={5} xl={6} md={16} xs={24}>
            <AutoComplete
              name="fullTextSearch"
              placeholder="Full text search..."
              label="Full text search"
            />
          </Col>,
          <Col key={7} xxl={4} xl={4} md={16} xs={24}>
            <EventCityOrZip
              label="City or postal code"
              placeholder="City or postal code..."
              showReset
              name="location"
            />
          </Col>,
          <Col key={8} xxl={4} xl={4} md={16} xs={24}>
            <Genres label="Genre" />
          </Col>,
          <Col key={9} xxl={4} xl={4} md={16} xs={24}>
            <TagsWrapper
              label="Event ID"
              name="eventID"
              placeholder="Event ID..."
            />
          </Col>,
          /**
           * @note TicketSpecials feature temporary removed - CP-671
           */
        ]}
        filterProps={{
          displayFiltersCount: 5,
        }}
        fetchPolicy="cache-and-network"
        useRowSelection
        onQueryUpdate={handleQueryChange}
        rowSelectionActions={[
          {
            label: 'Assign',
            icon: <PlusOutlined />,
            type: 'primary',
            onClick: handleAssign,
          },
          {
            label: 'Copy Maileon RSS-Feed URL',
            icon: (
              <img
                src={rssIcon}
                alt="RSS Icon"
                style={{
                  verticalAlign: 'middle',
                  marginRight: '8px',
                  height: '16px',
                }}
              />
            ),
            type: 'primary',
            onClick: generateRSSFeedURL,
          },
          {
            label: '(#1 Brand prefix solution)',
            icon: (
              <img
                src={rssIcon}
                alt="RSS Icon"
                style={{
                  verticalAlign: 'middle',
                  marginRight: '8px',
                  height: '16px',
                }}
              />
            ),
            type: 'primary',
            onClick: generateRSSFeedURLBrandPrefix,
          },
          {
            label: '(#2 Topic solution)',
            icon: (
              <img
                src={rssIcon}
                alt="RSS Icon"
                style={{
                  verticalAlign: 'middle',
                  marginRight: '8px',
                  height: '16px',
                }}
              />
            ),
            type: 'primary',
            onClick: generateRSSFeedURLTopics,
          },
          {
            label: '(#3 Extended solution)',
            icon: (
              <img
                src={rssIcon}
                alt="RSS Icon"
                style={{
                  verticalAlign: 'middle',
                  marginRight: '8px',
                  height: '16px',
                }}
              />
            ),
            type: 'primary',
            onClick: generateRSSFeedURLExtended,
          },
        ]}
        selectionLabelProvider={(count) => (
          <span>
            <strong>{count}</strong>
            {noop(count, ' event selected', ' events selected')}
          </span>
        )}
        columns={columns as any}
        query={GetEventsDocument}
        dataType="events"
      />
    </>
  );
};
